import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  Typography,
  Skeleton,
} from "@mui/material";
import { Link } from 'react-router-dom';
import FullScreenLayout from "../Layouts/FullScreenLayout";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

var items = [
  {
      src: "/images/car1.webp",
      description: "An exterior view of a part of a house that showcases the uPVC windows with dark frames from Vignorth Builders",
  },
  {
    src: "/images/car2.webp",
    description: "Outside view of a house's roof with white-framed uPVC awning windows and reflective glass.",
  },
  {
    src: "/images/car3.webp",
    description: "The exterior view of a white house with black, elegant-looking uPVC windows on the first and second floor.",
  },
  {
      src: "/images/car4.webp",
      description: "Vignorth Builders showcasing both fixed and sliding windows made of white, uPVC materials"
    },
    {
      src: "/images/car5.webp",
      description: "A beautiful image of uPVC Windows in triangle form as seen from the inside."
    },
    {
      src: "/images/car6.webp",
      description: "Vignorth Builders showcasing a fixed-type, modern-looking uPVC window with black frame."
    },
    {
      src: "/images/car7.webp",
      description: "A uPVC window in the shape of a circle."
    },
    {
      src: "/images/car8.webp",
      description: "The outside view of a white house with black window frames and clear glass."
    },
    {
      src: "/images/car9.webp",
      description: "An image of a construction site with a focus on the white uPVC awnings."
    },
    {
      src: "/images/car10.webp",
      description: "The side of a building with a series of white, uPVC fixed and awning windows."
    },
    {
      src: "/images/car11.webp",
      description: "Outside view of a house's swing and awning-type uPVC windows."
    },
    {
      src: "/images/car12.webp",
      description: "A tiled house with blue exterior walls and yellow roof with reflective windows."
    },
    {
      src: "/images/car13.webp",
      description: "A Vignorth Builders employee applying sealant on uPVC glass roofs."
    },
    {
      src: "/images/car14.webp",
      description: "The interior of a modern house with wooden staircases and glass railings."
    },
    {
      src: "/images/car15.webp",
      description: "An outside photograph of sliding doors with uPVC frames."
    },
];

const Gallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isImageListLoaded, setIsImageListLoaded] = useState(false); // State to track if image list has loaded
  const ismd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const skeletonArray = Array.from({ length: 8 }, (_, index) => index);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [skeletonWidth, setSkeletonWidth] = useState(ismd || isPortrait ? "29vw" : "22.6vw");
  const [skeletonHeight, setSkeletonHeight] = useState(ismd || isPortrait ? "25vh" : "50vh");

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseDialog = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Track number of loaded images
  let loadedImagesCount = 0;

  // Function to handle image load
  const handleImageLoad = () => {
    loadedImagesCount++;
    if (loadedImagesCount === items.length) {
      // All images have loaded
      setIsImageListLoaded(true);
    }
  };

  return (
    <>
      <FullScreenLayout imageUrl="">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ backgroundColor: "#DBE2EF" }}

        >
          <Grid container xs={11} md={11} sx={{marginTop:'20vh'}} >
            <Typography variant="h4" component="h4" sx={{width: '100%', textAlign:'left'}}>
              Our Projects
            </Typography>
            <Typography variant="body1" component="body1" sx={{width: '100%', textAlign:'left'}}>
                Check out some of our accomplished projects or <Link to="/contact">contact us</Link> for further inquiries.
            </Typography>
          </Grid>
          
          {!isImageListLoaded && (
  <Grid container xs={11} md={11} direction="row" justifyContent="flex-end" alignItems="center" sx={{paddingBottom:'10vh'}}>
    {skeletonArray.map((skel, index) => (
      <Grid item xs={4} md={3} sx={{paddingTop: '.7vh'}} key={index}>
        <Skeleton variant="rectangular" width={skeletonWidth} height={skeletonHeight} />
      </Grid>
    ))}
  </Grid>
)}
              
            <Grid container xs={11} md={11} direction="column" justifyContent="flex-end" alignItems="center" style={{ visibility: isImageListLoaded ? 'visible' : 'hidden' }}>
  <ImageList variant="masonry" cols={ismd ? 3 : 4} gap={8} >
    {items.map((item, index) => (
      <ImageListItem key={item.src}>
        <img
          srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
          src={`${item.src}?w=248&fit=crop&auto=format`}
          alt={item.description}
          loading="lazy"
          onLoad={handleImageLoad} // Call handleImageLoad when image is loaded
          onClick={() => handleImageClick(index)}
          style={{ cursor: "pointer" }}
        />
      </ImageListItem>
    ))}
  </ImageList>
</Grid>

            
        </Grid>
      </FullScreenLayout>

      {/* Dialog for displaying full screen image */}
      <Dialog
        open={selectedImageIndex !== null}
        onClose={handleCloseDialog}
        maxWidth="xl"
        PaperProps={{
          sx: {
            background: "none", // Make dialog transparent
            boxShadow: "none", // Remove default shadow
            overflow: "hidden",
            // height: "100%",
          },
        }}
      >
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "fixed",
            top: "8px",
            right: "8px",
            zIndex: 1500, // Ensure it's above the overlay
            color: "white",
          }}
        >
          <CancelRoundedIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
        {/* Overlay with semi-transparent background */}
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
            zIndex: 1300, // Ensure it's above the dialog
            cursor: "pointer", // Show pointer cursor
          }}
          onClick={handleCloseDialog} // Close dialog when clicked
        />

        {/* Dialog content */}
        <DialogContent
          sx={{
            position: "relative", // Ensure it's above the overlay
            zIndex: 1301, // Ensure it's above the overlay
            overflow: "hidden", // Hide scrollbars
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Make content take the whole height
            width: "90%",
          }}
        >
          {selectedImageIndex !== null && (
            <img
              src={`${items[selectedImageIndex].src}?w=600&fit=crop&auto=format`}
              alt={items[selectedImageIndex].description}
              style={{ maxWidth: "100%", maxHeight: "80vh", display: "block" }}
            />
          )}
        </DialogContent>

        {/* Dialog actions */}
        <DialogActions
          sx={{
            position: "relative", // Ensure it's above the overlay
            zIndex: 1301, // Ensure it's above the overlay
            justifyContent: "center",
            paddingBottom: "24px", // Add padding to separate from the image
          }}
        >
          <IconButton onClick={handlePrevImage}>
            <ArrowBackRoundedIcon sx={{ color: "white", fontSize: "2.5rem" }} />
          </IconButton>
          <IconButton onClick={handleNextImage}>
            <ArrowForwardRoundedIcon sx={{ color: "white", fontSize: "2.5rem" }} />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Gallery;
