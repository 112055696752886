import React, { useEffect, useState } from "react";
import EstimatorForm from "./EstimatorForm";
import EstimatorBreakDown from "./EstimatorBreakDown";
// import { fetchData } from "../Core/Axios";
import FullScreenLayout from "../Layouts/FullScreenLayout";
import { Box, Grid } from "@mui/material";
import { estimatorData } from "../Core/data";
import { estimatorChoices } from "../Core/data";

const Estimator = () => {
    const [groups, setGroups] = useState([]);
    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);
    const [qty, setQty] = useState(1);
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(estimatorChoices[0].value);
    const [priceDetails, setPriceDetails] = useState({
        vignetMaterialsTotal: 0,
        nonVignetMaterialsTotal: 0,
    });

    const getMaterials = async () => {
        try {
            // const fabricatedItems = await fetchData('/fabricated-items');
            setGroups(estimatorData[type]);
            
        } catch (error) {
        }
    }

    useEffect(() => {
        // console.log('estimator', estimatorData[type]);
        getMaterials();
    }, [type])

    return (
        <>
            <FullScreenLayout imageUrl="">
                    <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ backgroundColor: "#DBE2EF", minHeight: '100vh' }}
          >
            <Grid container xs={11} md={11}
            sx={{marginTop:'20vh'}}>

                        <EstimatorForm
                            estimator={{
                                width: width,
                                setWidth: setWidth,
                                height: height,
                                setHeight: setHeight,
                                qty: qty,
                                setQty: setQty,
                                price: price,
                                setPrice: setPrice,
                                priceDetails: priceDetails,
                                setPriceDetails: setPriceDetails,
                                type: type,
                                setType: setType,
                            }}
                        />
                        <EstimatorBreakDown
                            data={{
                                height: height,
                                width: width,
                                qty: qty,
                                groups: estimatorData[type],
                            }}
                            price={price}
                            setPrice={setPrice}
                            priceDetails={priceDetails}
                            setPriceDetails={setPriceDetails}
                        />

                    </Grid></Grid>
                {/* </Box> */}
            </FullScreenLayout>
        </>
    );
}

export default Estimator;
