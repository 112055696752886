import React, { useState, useEffect } from "react";
import {
  AppBar,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useScrollTrigger,
  Avatar, // Import Avatar component
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from '@mui/system';
import { siteRoutes } from "../Core/data";

const ElevationScroll = ({ children }) => {
  const [transparent, setTransparent] = useState(true);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: {
      backgroundColor: transparent ? '#F9F7F7aa' : '#F9F7F7',
      transition: 'background-color 0.3s',
    },
  });
};

const TopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname;

  const [value, setValue] = useState(currentTab);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  return (
    <ElevationScroll>
      <AppBar component="nav"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '10vh',
          padding: '0 24px',
        }}
      >
        <Box sx={{ padding: 1 }}>
          {/* Use Avatar component for the company logo */}
          <Avatar alt="Company Logo" src="/images/vignorthLogo.png" sx={{ width: 120, height: 120 }} />
        </Box>
        {isSmallScreen ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ color: '#112D4E' }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              
            >
              <List sx={{ width: '80vw', height: '100vh', backgroundColor:'#112D4E', color: '#F9F7F7' }}>
                {siteRoutes.map((route) => (
                  <ListItemButton
                    key={route.route}
                    selected={value === route.route}
                    onClick={() => {
                      setDrawerOpen(false);
                      setValue(route.route);
                      navigate(route.route);
                      handleLinkClick();
                    }}
                    sx={{ height: '8vh' }}
                  >
                    <ListItemText primary={route.route === '/' ? 'Home' : route.label} />
                  </ListItemButton>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs"
            sx={{
              '& .MuiTab-root': { color: '#112D4E', minHeight: '10vh', padding: '0 16px' },
              '& .MuiTabs-indicator': { bottom: '0' },
            }}
          >
            {siteRoutes.map((route) => (
              <Tab label={route.label} value={route.route} key={route.route} onClick={handleLinkClick}/>
            ))}
          </Tabs>
        )}
      </AppBar>
    </ElevationScroll>
  );
}

export default TopBar;
