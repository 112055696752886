export const siteRoutes = [
    {
        label: 'Home',
        route: '/'
    },
    {
        label: 'Gallery',
        route: '/gallery'
    },
    {
        label: 'Estimator',
        route: '/estimator'
    },
    {
        label: 'Contact',
        route: '/contact'
    },
];

export const estimatorChoices = [
  {
    label: 'Swing Door (SL 6000-plus)',
    value: 'swing-sl6000',
    image: 'swing-sl6000.webp'
  },
  {
    label: 'Sliding Windows (SS)',
    value: 'sliding_windows',
    image: 'sliding_windows.webp'
  }
];

export const estimatorData = {
  "swing-sl6000": [
      {
        "id": 1,
        "name": "SL 6000-plus",
        "markup": "28.00",
        "created_at": "2024-04-05T06:54:57.000000Z",
        "updated_at": "2024-04-05T06:54:57.000000Z",
        "fabricated_item_component_groups": [
          {
            "id": 1,
            "fabricated_item_id": 1,
            "discount": "25.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 1,
                "material_id": 1,
                "required_quantity_formula": "_width_+_height_*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 1,
                  "material_category_id": 1,
                  "code": "SL 601P",
                  "description": " Door Frame",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 1,
                      "material_id": 1,
                      "price": "164.52",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 2,
                "material_id": 2,
                "required_quantity_formula": "_width_*2+_height_*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 2,
                  "material_category_id": 2,
                  "code": "SL 602P",
                  "description": "Door Sash",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 2,
                      "material_id": 2,
                      "price": "225.06",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 3,
                "material_id": 3,
                "required_quantity_formula": "(_width_*2+_height_*2)*2",
                "total_formula": "(_quantity_*_price_)",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 3,
                  "material_category_id": 3,
                  "code": "VSC 430",
                  "description": "Mullion/Transom",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 3,
                      "material_id": 3,
                      "price": "32.47",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 4,
                "material_id": 4,
                "required_quantity_formula": "(_width_+_height_*2)",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 4,
                  "material_category_id": 3,
                  "code": "SL 609P",
                  "description": "Reinforcement for SL 601P",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 4,
                      "material_id": 4,
                      "price": "44.28",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 4,
                "material_id": 4,
                "required_quantity_formula": "(_width_*2+_height_*2)",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 4,
                  "material_category_id": 3,
                  "code": "SL 609P",
                  "description": "Reinforcement for SL 602P",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 4,
                      "material_id": 4,
                      "price": "44.28",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              }
            ]
          },
          {
            "id": 2,
            "fabricated_item_id": 1,
            "discount": "15.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 5,
                "material_id": 5,
                "required_quantity_formula": "8",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 5,
                  "material_category_id": 4,
                  "code": "CVR 06",
                  "description": "Cap Cover",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 5,
                      "material_id": 5,
                      "price": "9.12",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              
            ]
          },
          {
            "id": 3,
            "fabricated_item_id": 1,
            "discount": "0.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 9,
                "material_id": 9,
                "required_quantity_formula": "_width_*_height_*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 9,
                  "material_category_id": 7,
                  "code": "Glass Clear",
                  "description": "Glass Clear",
                  "unit": "square meter",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 9,
                      "material_id": 9,
                      "price": "600.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 10,
                "material_id": 10,
                "required_quantity_formula": "1",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 10,
                  "material_category_id": 7,
                  "code": "Door Knob",
                  "description": "Door Knob",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 10,
                      "material_id": 10,
                      "price": "750.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 11,
                "material_id": 11,
                "required_quantity_formula": "3",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 11,
                  "material_category_id": 7,
                  "code": "Hinges",
                  "description": "Hinges",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 11,
                      "material_id": 11,
                      "price": "150.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 12,
                "material_id": 12,
                "required_quantity_formula": "12",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 12,
                  "material_category_id": 7,
                  "code": "Fabrication",
                  "description": "Fabrication",
                  "unit": "foot",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 12,
                      "material_id": 12,
                      "price": "60.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 13,
                "material_id": 13,
                "required_quantity_formula": "2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 13,
                  "material_category_id": 7,
                  "code": "Install",
                  "description": "Install",
                  "unit": "hour",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 13,
                      "material_id": 13,
                      "price": "60.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 14,
                "material_id": 14,
                "required_quantity_formula": "(_width_+_height_)*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 14,
                  "material_category_id": 7,
                  "code": "Fixings",
                  "description": "Fixings",
                  "unit": "hour",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 14,
                      "material_id": 14,
                      "price": "100.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              
            ]
          }
        ]
      }
    ],
    



    "sliding_windows": [
      {
        "id": 1,
        "name": "Sliding Windows",
        "markup": "28.00",
        "created_at": "2024-04-05T06:54:57.000000Z",
        "updated_at": "2024-04-05T06:54:57.000000Z",
        "fabricated_item_component_groups": [
          {
            "id": 1,
            "fabricated_item_id": 1,
            "discount": "20.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 1,
                "material_id": 1,
                "required_quantity_formula": "(_width_*2+_height_*2)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 1,
                  "material_category_id": 1,
                  "code": "VS 420",
                  "description": "2 Track Frame w/ Net",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 1,
                      "material_id": 1,
                      "price": "104.67",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 2,
                "material_id": 2,
                "required_quantity_formula": "(_width_*2+_height_*4)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 2,
                  "material_category_id": 2,
                  "code": "VS 428",
                  "description": "Door Sash",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 2,
                      "material_id": 2,
                      "price": "135.86",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 3,
                "material_id": 3,
                "required_quantity_formula": "(_height_*2)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 3,
                  "material_category_id": 3,
                  "code": "VS 433",
                  "description": "Interlock",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 3,
                      "material_id": 3,
                      "price": "35.60",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 4,
                "material_id": 4,
                "required_quantity_formula": "0",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 4,
                  "material_category_id": 3,
                  "code": "VS 434",
                  "description": "In-Line Adapter",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 4,
                      "material_id": 4,
                      "price": "61.24",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 5,
                "material_id": 4,
                "required_quantity_formula": "(_width_*2+_height_*4)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 4,
                  "material_category_id": 3,
                  "code": "VSC 452",
                  "description": "Insect Screen Bead",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 4,
                      "material_id": 4,
                      "price": "37.91",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 6,
                "material_id": 4,
                "required_quantity_formula": "((_width_*2+_height_*4)*_qty_)/2",
                "total_formula": "_quantity_*_price_ * 0",
                "notes": "",
                "fabricated_item_component_group_id": 1,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 4,
                  "material_category_id": 3,
                  "code": "VS 446",
                  "description": "Insect Screen Sash",
                  "unit": "m",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 4,
                      "material_id": 4,
                      "price": "74.42",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              }
            ]
          },
          {
            "id": 2,
            "fabricated_item_id": 1,
            "discount": "10.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 5,
                "material_id": 5,
                "required_quantity_formula": "(_height_*8+_width_*4)*_qty_+_height_*2+(_width_/2)*2*_qty_+(((_width_*2+_height_*4)*_qty_)/2)",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 5,
                  "material_category_id": 4,
                  "code": "BS01",
                  "description": "Brush Seal",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 5,
                      "material_id": 5,
                      "price": "10.56",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 6,
                "material_id": 6,
                "required_quantity_formula": "_width_*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 6,
                  "material_category_id": 4,
                  "code": "SP 417",
                  "description": "Aluminum Track",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 6,
                      "material_id": 6,
                      "price": "82.10",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 7,
                "material_id": 7,
                "required_quantity_formula": "(_width_*2+_height_*4)*_qty_ - _width_",
                "total_formula": "_quantity_*_price_ * 0",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 7,
                  "material_category_id": 5,
                  "code": "VRS 4010",
                  "description": "Reinforcement for VS 428",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 7,
                      "material_id": 7,
                      "price": "157.90",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "((_width_*2+_height_*4)*_qty_)/2 - _width_/2",
                "total_formula": "_quantity_*_price_ * 0",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "VRS 4016T",
                  "description": "Reinforcment for VS 446",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "129.60",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "(_width_*2+_height_*4)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "VUG - 01",
                  "description": "Gasket Bead",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "23.04",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "(_width_*2+_height_*4)*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "VUG - 01",
                  "description": "Gasket Sash",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "23.04",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "((_width_*2+_height_*4)*_qty_)/2",
                "total_formula": "_quantity_*_price_*0",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "GS 303",
                  "description": "Gasket Insect Screen",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "10.56",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "4",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "SWR102G",
                  "description": "Roller for Sash",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "72.50",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "0.08",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "AP315A",
                  "description": "Universal Track Bridge",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "56.43",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "_qty_*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "ALB4",
                  "description": "Anti Lift Block",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "12.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "SS - 04",
                  "description": "Glider Sash Stopper",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "10.10",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "FH Han010W",
                  "description": "Flush Plastic Handle",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "20.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "8",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "FB 8x75",
                  "description": "Fixing Screws",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "14.40",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "8",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "CVR-06",
                  "description": "Cover CVR-06",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "9.12",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 8,
                "material_id": 8,
                "required_quantity_formula": "_qty_*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 2,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 8,
                  "material_category_id": 6,
                  "code": "DC1415",
                  "description": "Drainage Cover",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 8,
                      "material_id": 8,
                      "price": "3.36",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              }
            ]
          },
          {
            "id": 3,
            "fabricated_item_id": 1,
            "discount": "0.00",
            "created_at": "2024-04-05T06:54:57.000000Z",
            "updated_at": "2024-04-05T06:54:57.000000Z",
            "fabricated_item_components": [
              {
                "id": 9,
                "material_id": 9,
                "required_quantity_formula": "_width_*_height_*_qty_",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 9,
                  "material_category_id": 7,
                  "code": "Glass Clear",
                  "description": "Glass Clear",
                  "unit": "square meter",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 9,
                      "material_id": 9,
                      "price": "450.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 10,
                "material_id": 10,
                "required_quantity_formula": "2",
                "total_formula": "_quantity_*_price_*0",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 10,
                  "material_category_id": 7,
                  "code": "Screen Mesh",
                  "description": "Screen Mesh",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 10,
                      "material_id": 10,
                      "price": "450.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 11,
                "material_id": 11,
                "required_quantity_formula": "8",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 11,
                  "material_category_id": 7,
                  "code": "Fabrication",
                  "description": "Fabrication",
                  "unit": "piece",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 11,
                      "material_id": 11,
                      "price": "100.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 12,
                "material_id": 12,
                "required_quantity_formula": "4",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 12,
                  "material_category_id": 7,
                  "code": "Installation",
                  "description": "Installation",
                  "unit": "foot",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 12,
                      "material_id": 12,
                      "price": "100.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              },
              {
                "id": 13,
                "material_id": 13,
                "required_quantity_formula": "(_width_+_height_)*2",
                "total_formula": "_quantity_*_price_",
                "notes": "",
                "fabricated_item_component_group_id": 3,
                "created_at": "2024-04-05T06:54:57.000000Z",
                "updated_at": "2024-04-05T06:54:57.000000Z",
                "material": {
                  "id": 13,
                  "material_category_id": 7,
                  "code": "Fixings",
                  "description": "Fixings",
                  "unit": "hour",
                  "created_at": "2024-04-05T06:54:57.000000Z",
                  "updated_at": "2024-04-05T06:54:57.000000Z",
                  "prices": [
                    {
                      "id": 13,
                      "material_id": 13,
                      "price": "125.00",
                      "effective_date": "2024-04-03",
                      "created_at": "2024-04-05T06:54:57.000000Z",
                      "updated_at": "2024-04-05T06:54:57.000000Z"
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
  };