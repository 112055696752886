import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from 'react-router-dom';
import FullScreenLayout from "../Layouts/FullScreenLayout";
import Carousel from 'react-material-ui-carousel';

const items = [
  {
    src: "/images/car1.webp",
    alt: "An exterior view of a part of a house that showcases the uPVC windows with dark frames from Vignorth Builders",
  },
  {
    src: "/images/car2.webp",
    alt: "Outside view of a house's roof with white-framed uPVC awning windows and reflective glass.",
  },
  {
    src: "/images/car3.webp",
    alt: "The exterior view of a white house with black, elegant-looking uPVC windows on the first and second floor.",
  },
];

const addtlServices = [
  { label: 'Roll-up doors' },
  { label: 'Glass railings' },
  { label: 'uPVC glass roofs' },
  { label: 'Shower enclosures' },
  { label: 'Seamless windows' },
  { label: 'Frameless glass doors' },
]

const Home = () => {
  const boxRef = useRef(null);
  const boxRef2 = useRef(null);
  const gridrf2 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (boxRef.current) {
        const top = boxRef.current.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight * 0.5; // Adjust the threshold as needed
        if (isVisible) {
          boxRef.current.classList.add("appear-animation");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll2 = () => {
      if (gridrf2.current) {
        const top = gridrf2.current.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight * 0.5; // Adjust the threshold as needed
        if (isVisible) {
          gridrf2.current.classList.add("appear-animation-2"); // Add the class to gridrf2
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll2);
    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);
  

  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [imgHeight, setimgHeight] = useState(isMd || isPortrait ? "50vh" : "65vh");

  const scrollToBox = () => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToBox2 = () => {
    console.log('scrollToBox2 scrollToBox2');
    if (boxRef2.current) {
      boxRef2.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const scrollToTopAndNavigate = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <FullScreenLayout imageUrl='/images/vignorthbuilders.png'>
      {/* First Box */}
      <Box
        sx={{
          marginTop: "100vh",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "auto",
          margin: 0,
          padding: 0,
          backgroundImage: `url('/images/home_wall_op12.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid container xs={11} md={6} direction="column" justifyContent="flex-end" alignItems="center">
            <Typography variant="h6" sx={{ marginTop: '35vh', color: '#f9f7f7', textAlign: 'center' }}>
              With decades of expertise, we take pride in crafting quality uPVC doors and windows.
            </Typography>
          </Grid>
          <Grid item sx={{ paddingTop: '1rem' }}>
            <Button variant="contained" size="large" onClick={scrollToBox}>
              More
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Second Box */}
      <Box
  ref={boxRef}
  sx={{
    width: '100%',
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "relative",
    zIndex: -1,
    opacity: 0,
    transform: "translateY(20px)", // Adjust as needed
    transition: "opacity 0.5s ease, transform 0.5s ease",
    '&.appear-animation': {
      opacity: 1,
      transform: "translateY(0)",
    },
  }}
>

  <Grid
    container
    direction="row"
    justifyContent="space-evenly"
    alignItems="center"
    style={{
      marginTop:'5vh',
    }}
  >
    <Grid item xs={11} md={5} container direction="column" alignItems="center">
      <Typography variant="h6">Who we are</Typography>
      <Typography variant="body1" sx={{ textAlign: 'justify' }}>
        We are Vignet Builders. We specialize in the fabrication and installation of uPVC doors & windows.
        With decades of experience, we deliver top-notch craftsmanship that adds style and durability to any workplace or home.
      </Typography>
      <Grid item sx={{ paddingTop: '1rem' }}>
        <Button variant="contained" size="large" onClick={scrollToBox2}>
          More
        </Button>
      </Grid>
    </Grid>
    <Grid item xs={11} md={5}>
      <Carousel>
        {items.map((item, i) => (
          <Box
            key={i}
            component='img'
            src={item.src}
            alt={item.alt}
            sx={{
              height: imgHeight,
              mt: 1,
              width: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
            }}
          />
        ))}
      </Carousel>
    </Grid>
  </Grid>
</Box>

      <Box
    ref={boxRef2}
    style={{
      position: "relative", // Set position to absolute
      top: 0, // Position at the top of the parent container
      width: "100%",
      minHeight: "100vh", // Make sure it covers the viewport
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      backgroundColor: "#F9F7F7",
      transform: "skewY(-3deg)",
      zIndex: -1,
      paddingTop: "2rem", // Add padding to the top
    paddingBottom: "2rem", // Add padding to the bottom
    }}
  >
        <Grid
    ref={gridrf2} // Reference for gridrf2
    container
    direction="row"
    justifyContent="space-evenly"
    alignItems="center"
    sx={{
      transform: "translateY(20px)", // Adjust the skewY degree here
      opacity: 0,
      transition: "opacity 0.5s ease, transform 0.5s ease",
      "&.appear-animation-2": { // Add the class for opacity animation
        opacity: 1,
        transform: "translateY(0)",
      },
    }}
  >
          
          <Grid item xs={11} md={5} sx={{transform: "skewY(3deg)",}}>
          <Box
        sx={{
          marginTop: "100vh",
          height: imgHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "auto",
          margin: 0,
          padding: 0,
          backgroundImage: `url('/images/working2.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></Box>
            
            
          </Grid>
          <Grid item xs={11} md={5} container direction="column" alignItems="center" sx={{transform: "skewY(3deg)",}}>
            <Typography variant="h6">What we do</Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify' }}>
            Our process involves carefully measuring, fabricating, and installing uPVC doors & windows to ensure a perfect fit and seamless integration into your space.
            In addition to our core specialization, we offer a comprehensive range of services to meet all your needs, including:

            <Box component="ul" sx={{ paddingLeft: 0, listStyleType: "none" }}>
            <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          {addtlServices.map((srvc, index) => (
            <Grid item xs={12} md={6}>
            <Box
              component="li"
              sx={{ listStyleType: "none", marginBottom: "0.5rem" }}
            >
               <Typography
                variant="body1"
                color="inherit"
                sx={{
                  textDecoration: "none",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              ><span
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    marginRight: "0.5rem",
                  }}
                />{srvc.label}</Typography>

              </Box>
              </Grid>
          ))}
              </Grid>
            </Box>

            </Typography>
            <Grid item sx={{ padding: '1rem' }}>
              <Link to="/gallery" style={{ textDecoration: 'none' }}>
                <Button variant="contained" size="large" onClick={scrollToTopAndNavigate}>
                  Check our Gallery
                </Button>
              </Link>
            </Grid>
          </Grid>
          
        </Grid>
      </Box>
    </FullScreenLayout>
  );
};

export default Home;
