import ReactDOM from "react-dom/client";
import AppContent from "./Core/App";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'typeface-roboto';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F72AF',
    },
    secondary: {
      main: '#112D4E',
    },
    text: {
      primary: '#101010', // Set text color here
    },
  },
  typography: {
    fontFamily: [
      'Roboto', // Use Roboto font
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontSize: '1.1rem', // Adjust default text size here
    },
  },
  tab: {
    background: '#F90000',
    '&.Mui-selected': {
      background: '#ff0000',
    },
  },
});



function App() {
    return(
  <ThemeProvider theme={theme}>
    <Box style={{ backgroundColor: '#DBE2EF', height: '100%', width: '100%' }}>
      <AppContent />
    </Box>
  </ThemeProvider>
    );
}



export default App;
