import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Typography,
    useMediaQuery,
    Grid,
} from "@mui/material";
import { contactDetails } from "../Core/constants";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box } from '@mui/system';
import { siteRoutes } from "../Core/data";

function FullScreenLayout({ children, imageUrl }) {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [footerHeight, setFooterHeight] = useState(isMd || isPortrait ? "95vh" : "60vh");


  const handleLinkClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  return (
    <Box
      style={{
        // position: 'fixed',
        width: '100%',
        height: '100%',
        overflowWrap: 'break-word',
        zIndex: 0,
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      />
      
      <Box
        style={{
          position: 'relative',
          zIndex: 1,
          minHeight: '100vh', // Ensure footer always fills the viewport height
          
        }}
      >
        {children}
        {/* footer */}
        <Box
            sx={{
              marginTop: "100vh", // Position box at a screen's height below the viewport
              backgroundColor: "#3F72AFff",
              height: footerHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative", // Position relative to its normal position in the document flow
              overflow: "auto", // Enable scrolling
              margin: 0, // Remove default margin
              padding: 3, // Remove default padding
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="h4" component="h4">
                    VIGNORTH BUILDERS
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'left-align' }}>
                  uPVC DOORS AND WINDOWS
                  FABRICATION AND INSTALLATION
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} md={4}>
              <Box>
      <Typography variant="h4" gutterBottom>
        Links
      </Typography>
      <Box component="ul" sx={{ paddingLeft: 0, listStyleType: "none" }}>
      <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={3}
        >
        {siteRoutes.map((route, index) => (

          <Grid item xs={6} md={6}>
            <Box
              component="li"
              key={index}
              sx={{ listStyleType: "none", marginBottom: "0.5rem" }}
            >
              <Typography
                variant="body1"
                component={Link}
                to={route.route}
                color="inherit"
                onClick={handleLinkClick}
                sx={{
                  textDecoration: "none",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    marginRight: "0.5rem",
                  }}
                />
                {route.label}
              </Typography>
            </Box>
            </Grid>
        ))}
        </Grid>
      </Box>
    </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="h4" component="h4">
                    Contact Us
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocationOnIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.address }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <PhoneIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.phone }
                      </Typography>
                    </Typography>
                  </Box>
                  {/* SmartphoneIcon */}
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <SmartphoneIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.mobile }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <EmailIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.email }
                      </Typography>
                    </Typography>
                    
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FacebookIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.facebook }
                      </Typography>
                    </Typography>
                    
                  </Box>
                  <Box sx={{ mt: 1 }}>
                  <Typography 
    variant="body1" 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      overflowWrap: 'break-word' // Add this property to enable text wrapping
    }}
  >
                      <InstagramIcon sx={{ verticalAlign: 'middle' }} />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1, maxWidth: '90%' }}>
                      { contactDetails.instagram }
                      </Typography>
                    </Typography>
                    
                  </Box>
                  {
                    // !isMd &&
                    // <Box component="img" src="/images/qrlogo.webp" alt="Description" sx={{ mt: 1, height: '30vh' }}/>
                  }
                  
                </Box>

              </Grid>
              
            </Grid>
          </Box>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #42648d", // Border top to separate from the content above
          padding: 2, // Add some padding at the top
          textAlign: "center", // Center the text
          backgroundColor: "#3F72AFff",
        }}
      >
        <Typography variant="body2">
          Copyright © 2024 Vignorth Builders
        </Typography>
      </Box>
    </Box>
  );
}


export default FullScreenLayout;
