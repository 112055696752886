import React, { useState } from "react";
import { Box, Grid, Typography, Tooltip, IconButton, useMediaQuery } from "@mui/material";
import FullScreenLayout from "../Layouts/FullScreenLayout";
import copy from 'clipboard-copy';
import { contactDetails } from "../Core/constants";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';


const Contact = () => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [mapHeight, setmapHeight] = useState(isMd || isPortrait ? "40vh" : "65vh");
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        await copy(text); // Fallback to clipboard-copy library
      }
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleRedirect = (link) => {
    window.location.href = link;
  };

  return (
    <FullScreenLayout imageUrl="">
      <Box
        sx={{
          marginTop: "100vh", // Position box at a screen's height below the viewport
          height: "120vh",
          display: "flex",
          backgroundColor: "#DBE2EF",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Position relative to its normal position in the document flow
          overflow: "auto", // Enable scrolling
          margin: 0, // Remove default margin
          padding: 0, // Remove default padding
          // backgroundImage: `url('/images/bg2.webp')`,
          //       backgroundSize: 'cover',
          //       backgroundPosition: 'center',
          //       backgroundRepeat: 'no-repeat',
          
        }}
      >

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{width: "80%"}}
          >
          <Grid container xs={12} md={4}
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{overflowWrap: 'break-word'}}
            spacing={2}
          >
            <Typography variant="h4" component="h4">
                    Contact Us
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => {copyToClipboard('115 KM3 Marcos Highway, Bakakeng Central, Baguio City')}}>
                        <Tooltip title="Copy" arrow>
                          <IconButton>
                            <LocationOnIcon sx={{ verticalAlign: 'middle' }} />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                        { contactDetails.address }
                        </Typography>
                        
                      </Typography>
                    
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => {copyToClipboard('(074) 446-6593')}}>
                      <Tooltip title="Copy" arrow>
                        <IconButton>
                          <PhoneIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.phone }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => {copyToClipboard('(074) 446-6593')}}>
                      <Tooltip title="Copy" arrow>
                        <IconButton>
                          <SmartphoneIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.mobile }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => {copyToClipboard('vignorthbuilders@gmail.com')}}>
                      <Tooltip title="Copy" arrow>
                        <IconButton>
                          <EmailIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.email }
                      </Typography>
                    </Typography>
                    
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => {handleRedirect('https://facebook.com/vignorthbuilders')}}>
                      {/* <Tooltip title="Copy" arrow> */}
                        <IconButton>
                          <FacebookIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      {/* </Tooltip> */}
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      { contactDetails.facebook }
                      </Typography>
                    </Typography>
                    
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', cursor:'pointer', overflowWrap: 'break-word'}} onClick={() => {handleRedirect('https://instagram.com/vignorthbuilders')}}>
                      {/* <Tooltip title="Copy" arrow> */}
                        <IconButton>
                          <InstagramIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      {/* </Tooltip> */}
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'middle', ml: 1, maxWidth: '90%' }}>
                        { contactDetails.instagram }
                      </Typography>
                    </Typography>
                    
                  </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={{paddingTop: '1rem'}}>
            <Box
              sx={{
                marginTop: "100vh",
                height: mapHeight,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
                margin: 0,
                padding: 0,
                backgroundImage: `url('/images/mapsample1.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
              }}
              onClick={() => {handleRedirect('https://www.google.com/maps/dir//16.3970776,120.5824774/@16.3970191,120.5824949,106m/data=!3m1!1e3?entry=ttu')}}
            />
          </Grid>
          
          </Grid>

      </Box>
    </FullScreenLayout>
  );
}

export default Contact;