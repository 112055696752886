import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageTemplate from "../Layouts/PageTemplate";
import Gallery from "../Pages/Gallery";
import Contact from "../Pages/Contact";
import NotFound from "../Pages/NotFound";
import Estimator from "../Pages/Estimator";
import Home from "../Pages/Home";

const routes = [
  { path: "/", element: <Home /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "/estimator", element: <Estimator /> },
  { path: "/contact", element: <Contact /> },
];

const AppContent = () => {
  return (
    <BrowserRouter>
      <PageTemplate>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} exact path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageTemplate>
    </BrowserRouter>
  );
};

export default AppContent;
