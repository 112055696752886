import React, { useEffect, useState } from "react";
import { StaticTable } from "../Common/StaticTable";
import { Box, Grid } from "@mui/material";
import { evaluate } from 'mathjs';

const EstimatorBreakDown = ({data, price, setPrice, priceDetails, setPriceDetails}) => {
    const {
        groups = [],
        width=1,
        height=1,
        qty=1,
    } = data;

    const tableHeaders = [
        ['Profile Description', 'Product Code', 'Required Length in meters', 'Price per meter', 'Total PHP'],
        ['Hardware Description', 'Product Code', 'Required Quantity', 'Price per piece', 'Total PHP'],
        ['Hardware Description', '', 'Required Quantity', 'Price per piece', 'Total PHP'],
        ['Profile Description', 'Total PHP'],
        ['Hardware Description', 'Total PHP'],
        ['Hardware Description', 'Total PHP'],
    ];

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [components, setComponents] = useState([]);
    const [endRows, setEndRows] = useState([]);
    const [markup, setMarkup] = useState(0);

    const [calculatedPrice, setCalculatedPrice] = useState(0);

    const getComponents = (items) => {
        // console.log('items', items);
        const formattedComponents = items.map((grp) => {
            return grp.fabricated_item_components.map((component) => {
                // console.log('cmp', component);
                const requiredQty = evaluate(component?.required_quantity_formula
                    .replace(/_width_/g, width)
                    .replace(/_height_/g, height)
                    .replace(/_qty_/g, qty));
                const evaluatedTotal = evaluate(component?.total_formula
                    .replace(/_width_/g, width)
                    .replace(/_height_/g, height)
                    .replace(/_price_/g, component?.material?.prices[0]?.price)
                    .replace(/_qty_/g, qty)
                    .replace(/_quantity_/g, requiredQty));
    
                return [
                    // component?.material?.description,
                    // component?.material?.code,
                    // requiredQty < 0 ? '0.00' : requiredQty.toFixed(2),
                    // component?.material?.prices[0]?.price,
                    // evaluatedTotal < 0 ? '0.00' : evaluatedTotal.toFixed(2),
                    component?.material?.description,
                    component?.material?.code,
                    requiredQty < 0 ? '0.00' : requiredQty.toFixed(2),
                    component?.material?.prices[0]?.price,
                    evaluatedTotal < 0 ? '0.00' : evaluatedTotal.toFixed(2),
                ];
            });
        });
        // console.log('compon', formattedComponents);
        return formattedComponents;
    };

    const getEndRows = (items, groups) => {
        // console.log('end', groups);

        // console.log('items', items);
        const sum = groups.map((grp, index) => {
            const grpsum = grp.reduce((val, component) => {
                return val + parseFloat(component[component.length-1]);
            }, 0);
            const fgrpsum = parseFloat(grpsum.toFixed(2)).toFixed(2);
            // console.log(fgrpsum, items[index].discount/100);
            const discounted = parseFloat(fgrpsum*(items[index].discount/100)).toFixed(2);
            const ends = [
                // ['','','','Subtotal', fgrpsum],
                // ['','','','Discount', discounted],
                // ['','','','Total', (fgrpsum-discounted).toFixed(2)],
                ['Subtotal', fgrpsum],
                ['Discount', discounted],
                ['Total', (fgrpsum-discounted).toFixed(2)],
            ];
            return ends;
        });
        return sum;
    };

    const getTotals = (endrows) => {
        const allsum = endrows.map((rows, endrowsidx) => {
            return rows.reduce((val, total, idx) => {
                
                if (idx == rows.length-1) {
                    return total[total.length-1];
                }
                
            }, 0);
        });

        const sm = allsum.reduce((val, t) => {
            return val + parseFloat(t);
        }, 0);
        return {
            'all_sum': sm,
            'markup_computed': (sm*(markup/100)).toFixed(2),
            'with_markup': (sm+(sm*(markup/100))).toFixed(2),
        };
    };

    const areNumbers = (numbers) => {
        // Regular expression to match valid numbers (integers and floating-point numbers)
        const numberRegex = /^[+-]?\d+(\.\d+)?$/;
      
        // Check if every element in the array matches the number regex
        return numbers.every(num => numberRegex.test(num));
      };

    useEffect(() => {
        // console.log('groupsss',data);
        if (data?.groups && areNumbers([width, height, qty])) {
            
            setItems(data.groups[0]?.fabricated_item_component_groups);
            setMarkup(data.groups[0]?.markup);
            setTotal(0);
            const componentsData = data.groups[0]?.fabricated_item_component_groups;
            getComponents(componentsData);
            // Call getEndRows after setting components
            
            const formattedComponents = getComponents(componentsData);
            // console.log('formattedComponents', formattedComponents);
            setComponents(formattedComponents);
            const endrows = getEndRows(componentsData, formattedComponents);
            // console.log('end', endrows[0][2][1]);
            setEndRows(endrows);
            const x = getTotals(endrows);
            setTotal(x);
            // setProfPrice(x);
            const newPrice = parseFloat(x.with_markup);
            if (newPrice !== calculatedPrice) {
                setCalculatedPrice(newPrice);
            }
        }
    }, [data]);

    useEffect(() => {
        if (calculatedPrice !== price) {
            setPrice(calculatedPrice);
            const vignetmatTotal = (parseFloat(endRows[0][2][1]) + parseFloat(endRows[1][2][1])).toFixed(2);
            const vignetmatwithmarkup = (parseFloat(vignetmatTotal) + parseFloat(vignetmatTotal * (markup/100))).toFixed(2);
            const nonvignetmatwithmarkup = (parseFloat(endRows[2][2][1]) + parseFloat(endRows[2][2][1] * (markup/100))).toFixed(2);
            setPriceDetails({
                vignetMaterialsTotal: vignetmatwithmarkup,
                nonVignetMaterialsTotal: nonvignetmatwithmarkup,
            });
            // console.log('calculatedPrice', calculatedPrice);
        }
    }, [calculatedPrice, price]);

    return (
        <>
            {/* {items && items.map((fg, index) => {
                return (
                    <StaticTable
                        key={`profiles-${index}`}
                        title='profiles'
                        headers={tableHeaders[index]}
                        rows={components[index]}
                        endRows={endRows[index]}
                    />
                );
            })} */}
        </>
    );
}

export default EstimatorBreakDown;