import React from 'react';
import { Grid } from '@mui/material';
import TopBar from './TopBar';
import { Box } from '@mui/system';

const PageTemplate = ({ children }) => {
    return (
        <>
            <TopBar/>
            <Box>
                { children }
            </Box>
            
        </>
    );
};

export default PageTemplate;