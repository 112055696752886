import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import FullScreenLayout from "../Layouts/FullScreenLayout";

const NotFoundPage = () => {
  return (
    <FullScreenLayout imageUrl="">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#F9F7F7",
          transform: "skewY(-3deg)",
          minHeight: '100vh'
        }}
      >
        <Typography variant="h4" component="h4" sx={{ textAlign: 'center', transform: "skewY(3deg)", }}>
          PAGE NOT FOUND
        </Typography>
        <Grid item sx={{ paddingTop: '1rem', transform: "skewY(3deg)" }}>
          <Link to="/">
            <Button variant="contained" size="large">
              Back to Home
            </Button>
          </Link>
        </Grid>
      </Grid>
    </FullScreenLayout>
  );
}

export default NotFoundPage;
