import React from "react";
import { Grid, Box, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Link } from 'react-router-dom';

// Import estimatorChoices array
import { estimatorChoices } from "../Core/data";

const validationSchema = Yup.object().shape({
    width: Yup.number().min(0, 'must be > 0').required("Width is required").typeError('must be a number'),
    height: Yup.number().min(0, 'must be > 0').required("Height is required").typeError('must be a number'),
    qty: Yup.number().min(0, 'must be > 0').required("Quantity is required").typeError('must be a number'),
});

const EstimatorForm = ({ estimator }) => {

    return (
        <Formik
            initialValues={{
                width: estimator.width || 1,
                height: estimator.height || 1,
                qty: estimator.qty || 1,
                type: estimator.type,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                // Handle form submission
            }}
        >
            {/* {({ touched, errors, setFieldValue }) => ( */}
            {({ values, touched, errors, setFieldValue }) => (
                <Form>
                    <Grid container spacing={0} direction="row" sx={{color:'#000000'}}>
                        <Grid container xs={11} md={11}>
                            <Typography variant="h4" component="h4" sx={{width: '100%', textAlign:'left'}}>
                                Sample Estimator
                            </Typography>
                            <Typography variant="body1" component="body1" sx={{width: '100%', textAlign:'left'}}>
                                Try out our free sample estimator tailored for estimating for white profiles and clear glass, or <Link to="/contact">contact us</Link> for further inquiries.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ padding: 1 }}>
                                <FormControl fullWidth variant="outlined" error={!!(touched.type && errors.type)}>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        labelId="type-label"
                                        label="Type"
                                        name="type"
                                        size="small"
                                        value={estimator.type}
                                        onChange={(e) => {
                                            setFieldValue('type', e.target.value);
                                            estimator.setType(e.target.value);
                                        }}
                                    >
                                        {/* Map over the estimatorChoices array and create MenuItem components */}
                                        {estimatorChoices.map(choice => (
                                            <MenuItem key={choice.value} value={choice.value}>
                                                {choice.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ padding: 1 }}>
                                <TextField
                                    name="width"
                                    label={'Width (meters)'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={values.width}
                                    error={!!(touched.width && errors.width)}
                                    helperText={touched.width && errors.width}
                                    onChange={(e) => {
                                        setFieldValue('width', e.target.value);
                                        estimator.setWidth(parseFloat(e.target.value));
                                    }}
                                />
                            </Box>
                            <Box sx={{ padding: 1 }}>
                                <TextField
                                    name="height"
                                    label={'Height (meters)'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={values.height}
                                    error={!!(touched.height && errors.height)}
                                    helperText={touched.height && errors.height}
                                    onChange={(e) => {
                                        setFieldValue('height', e.target.value);
                                        estimator.setHeight(parseFloat(e.target.value));
                                    }}
                                />
                            </Box>
                            {/* <Box sx={{ padding: 1 }}>
                                <Field
                                    name="qty"
                                    as={TextField}
                                    label={'Quantity'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    error={!!(touched.qty && errors.qty)}
                                    helperText={touched.qty && errors.qty}
                                    onChange={(e) => {
                                        setFieldValue('qty', e.target.value);
                                        estimator.setQty(parseFloat(e.target.value));
                                    }}
                                />
                            </Box> */}
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ padding: 1 }}>
                            <Box
                                sx={{
                                    height: "100%", // Set height to 100% of its container
                                    width: "100%", // Set width to 100% of its container
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                    overflow: "hidden",
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                    <img
                                        src={`/images/${estimator.type}.webp`} // Assuming this is the correct path to your image
                                        alt="illustration of selected item"
                                        style={{
                                            width: "100%", // Make the image take up 100% of the width
                                            height: "100%", // Make the image take up 100% of the height
                      
                                            
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container direction="column" item xs={12} md={4}>
                            <Box sx={{ padding: 1 }}>
                                <TextField
                                    name=""
                                    label={'Profile Materials'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    error={''}
                                    helperText={''}
                                    sx={{ '& input': { textAlign: 'right' } }}
                                    value={estimator.priceDetails.vignetMaterialsTotal}
                                />
                            </Box>
                            <Box sx={{ padding: 1 }}>
                                <TextField
                                    name=""
                                    label={'Other Materials'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    error={''}
                                    helperText={''}
                                    sx={{ '& input': { textAlign: 'right' } }}
                                    value={estimator.priceDetails.nonVignetMaterialsTotal}
                                />
                            </Box>
                            
                            <Box sx={{ padding: 1 }}>
                                <TextField
                                    name=""
                                    label={'Estimated Price'}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    error={''}
                                    helperText={''}
                                    sx={{ '& input': { textAlign: 'right' } }}
                                    value={estimator.price}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default EstimatorForm;
